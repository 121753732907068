/**
 * Home page image galleries
 */
var HomeGallery = new function () {
  /**
   * Internal context
   */
  var _this = this

  /**
   * Class elements
   */
  this.galleryDiv = document.querySelector('.hero-popular__flickity')
  this.galleryItem = '.hero-popular__item'

  /**
   * Start picture gallery
   */
  this.startGallery = function () {
    var flkty = new Flickity(_this.galleryDiv, {
      autoPlay: false,
      cellSelector: _this.galleryItem,
      pageDots: false,
      imagesLoaded: true
    })
  }

  /**
   * Initialize the class
   */
  this.init = function () {
    if (_this.galleryDiv) {
      _this.startGallery()
    }
  }
}()
