/**
 * Favorite link (button) handler
 * @return {object}   current public object
 */
const Favorite = (function () {
  /**
   * Make private variable
   * @type {object}
   */
  const _this = this

  /**
   * Send ajax request
   */
  _this.sendRequest = function () {
    fetch(_this.button.href, {
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Accept-Type': 'application/json'
        }
      })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        if (data.status === 'ok') {
          _this.changeClass()
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  /**
   * Change active and inactive class for current button
   * @return {boolean}
   */
  _this.changeClass = function () {
    if (_this.button.classList.contains('btn--fav-link')) {
      _this.button.classList.remove('btn--fav-link')
      _this.button.classList.add('btn--active-fav-link')

      return true
    }

    if (_this.button.classList.contains('btn--active-fav-link')) {
      _this.button.classList.remove('btn--active-fav-link')
      _this.button.classList.add('btn--fav-link')

      return true
    }
  }

  /**
   * Click event handler
   * @param  {object} event click event
   */
  _this.clicked = function (event) {
    event.preventDefault()
    _this.button = event.currentTarget

    _this.sendRequest()
  }

  /**
   * Initialize app and create events
   */
  this.init = function () {
    console.log('anyad')
    const favoriteButtons = document.querySelectorAll('[data-favorite-button]')
    if (favoriteButtons.length > 0) {
      favoriteButtons.forEach(button => {
        button.addEventListener('click', _this.clicked)
      })
    }
  }

  return this
})()
