function forEach (array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i])
  }
}

function showElem (elem) {
  if (elem) {
    elem.style.display = 'block'
  }
}

function showByClass (cl) {
  var elems = document.getElementsByClassName(cl)

  if (elems.length) {
    forEach(elems, function (index, elem) {
      elem.style.display = 'block'
    })
  }
}

function hideElem (elem) {
  elem.style.display = 'none'
}

function hideByClass (cl) {
  var elems = document.getElementsByClassName(cl)

  if (elems.length) {
    forEach(elems, function (index, elem) {
      elem.style.display = 'none'
    })
  }
}

function toggleElem (elem) {
  if (elem.style.display === 'block') {
    elem.style.display = 'none'
  } else {
    elem.style.display = 'block'
  }
}

function toggleByClass (cl) {
  var elems = document.getElementsByClassName(cl)

  if (elems.length) {
    forEach(elems, function (index, elem) {
      if (elem.style.display === 'block') {
        elem.style.display = 'none'
      } else {
        elem.style.display = 'block'
      }
    })
  }
}

function hasClass (elem, cl) {
  return new RegExp(' ' + cl + ' ').test(' ' + elem.className + ' ')
}

function addClass (selector, cl) {
  if (typeof selector === 'object') {
    var elem = selector

    if (!hasClass(elem, cl)) {
      elem.className === '' ? elem.className = cl : elem.className += ' ' + cl
    }
  }

  if (typeof selector === 'string') {
    var elems = document.querySelectorAll(selector)

    if (elems.length) {
      forEach(elems, function (index, elem) {
        if (!hasClass(elem, cl)) {
          elem.className === '' ? elem.className = cl : elem.className += ' ' + cl
        }
      })
    }
  }

  return null
}

function removeClass (selector, cl) {
  if (typeof selector === 'object') {
    var elem = selector
    var newcl = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' '

    if (hasClass(elem, cl)) {
      while (newcl.indexOf(' ' + cl + ' ') >= 0) {
        newcl = newcl.replace(' ' + cl + ' ', ' ')
      }
      elem.className = newcl.replace(/^\s+|\s+$/g, '')
    }
  }

  if (typeof selector === 'string') {
    var elems = document.querySelectorAll(selector)

    if (elems.length) {
      forEach(elems, function (index, elem) {
        var newcl = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' '

        if (hasClass(elem, cl)) {
          while (newcl.indexOf(' ' + cl + ' ') >= 0) {
            newcl = newcl.replace(' ' + cl + ' ', ' ')
          }
          elem.className = newcl.replace(/^\s+|\s+$/g, '')
        }
      })
    }
  }

  return null
}

function toggleClass (selector, cl) {
  if (typeof selector === 'object') {
    if (selector.length > 0) {
      forEach(selector, function (index, elem) {
        hasClass(elem, cl) ? removeClass(elem, cl) : addClass(elem, cl)
      })
    } else {
      hasClass(selector, cl) ? removeClass(selector, cl) : addClass(selector, cl)
    }
  }

  if (typeof selector === 'string') {
    var elems = document.querySelectorAll(selector)

    if (elems.length) {
      forEach(elems, function (index, elem) {
        hasClass(elem, cl) ? removeClass(elem, cl) : addClass(elem, cl)
      })
    }
  }

  return null
}

function elemHeight (selector) {
  if (typeof selector === 'object') {
    var elem = selector
  }

  if (typeof selector === 'string') {
    var elem = document.querySelector(selector)
  }

  var elHeight = elem.offsetHeight
  elHeight += parseInt(window.getComputedStyle(elem).getPropertyValue('padding-top'))
  elHeight += parseInt(window.getComputedStyle(elem).getPropertyValue('padding-bottom'))

  return elHeight
}

function scrollTo (elem, to, duration) {
  if (duration <= 0) {
    return
  }

  var difference = to - elem.scrollTop
  var perTick = difference / duration * 10

  setTimeout(function () {
    elem.scrollTop = elem.scrollTop + perTick

    if (elem.scrollTop === to) {
      return
    }

    scrollTo(elem, to, duration - 10)
  }, 10)
}

function scrollToElem (elem, offset) {
  if (typeof selector === 'object') {
    var elem = selector
  }

  if (typeof selector === 'string') {
    var elem = document.querySelector(selector)
  }

  var scrollHeight = elem.getBoundingClientRect().top + parseInt(offset)

  scrollTo(document.body, scrollHeight, 500)
}
