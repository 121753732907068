document.addEventListener('DOMContentLoaded', function () {
  document.querySelector('html').classList.remove('no-js')

  if (typeof Header !== 'undefined') {
    Header.init()
  }

  if (typeof GalleryFilters !== 'undefined') {
    GalleryFilters.init()
  }

  if (typeof Scroller !== 'undefined') {
    Scroller.init()
  }

  if (typeof Lightbox !== 'undefined') {
    Lightbox.init()
  }

  if (typeof Quantity !== 'undefined') {
    Quantity.init()
  }

  if (typeof HomeGallery !== 'undefined') {
    HomeGallery.init()
  }

  if (typeof TestimonialsGallery !== 'undefined') {
    TestimonialsGallery.init()
  }

  if (typeof CarouselGallery !== 'undefined') {
    CarouselGallery.init()
  }

  if (typeof FormHidden !== 'undefined') {
    FormHidden.init()
  }

  if (typeof CartSummary !== 'undefined') {
    CartSummary.init()
  }

  if (typeof Tabs !== 'undefined') {
    Tabs.init()
  }

  if (typeof Masonry !== 'undefined') {
    Masonry.init()
  }

  if (typeof Favorite !== 'undefined') {
    Favorite.init()
  }

  if (typeof CartPriceController !== 'undefined') {
    CartPriceController.init()
  }

  if (typeof Checkout !== 'undefined') {
    Checkout.init()
  }

  if (typeof Autocomplete !== 'undefined') {
    Autocomplete.init()
  }
})
