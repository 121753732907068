/**
 * Functions associated with the header navigation
 */
var Header = new function () {
  /**
   * Internal context
   */
  var _this = this

  /**
   * Class elements
   */
  this.navWrapper = document.querySelector('.site-header__menu')
  this.navToggler = document.querySelector('.site-header__mobile-toggler')

  /**
   * Toggle header menu
   */
  this.toggleMenu = function () {
    toggleClass(_this.navWrapper, 'is-open')
  }

  /**
   * Initialize the class
   * Register event listeners
   */
  this.init = function () {
    _this.navToggler.addEventListener('click', function () {
      _this.toggleMenu()
    })
  }
}()
