/**
 * Home page testimonials
 */
var TestimonialsGallery = new function () {
  /**
   * Internal context
   */
  var _this = this

  /**
   * Class elements
   */
  this.testimonialsDiv = document.querySelector('.hero-testimonials__flickity')
  this.testimonialsItem = '.hero-testimonials__item'

  /**
   * Start testiomonials carousel
   */
  this.startGallery = function () {
    var flkty = new Flickity(_this.testimonialsDiv, {
      autoPlay: 6000,
      cellSelector: _this.testimonialsItem,
      prevNextButtons: false,
      pageDots: true
    })
  }

  /**
   * Initialize the class
   */
  this.init = function () {
    if (_this.testimonialsDiv) {
      _this.startGallery()
    }
  }
}()
