/**
 * Scroll to specific target
 */
var Scroller = new function () {
  /**
   * Internal context
   */
  var _this = this

  /**
   * Class elements
   */
  this.scrollLinks = document.querySelectorAll('[data-scrollto]')

  /**
   * Scrolling
   */
  this.doScroll = function (target) {
    var targetDiv = document.querySelector('[data-scrolltarget="' + target + '"]')

    if (targetDiv) {
      scrollToElem(targetDiv, -20)
    }
  }

  /**
   * Initialize the class
   */
  this.init = function () {
    if (_this.scrollLinks) {
      forEach(_this.scrollLinks, function (index, elem) {
        elem.addEventListener('click', function (e) {
          e.preventDefault()
          _this.doScroll(this.dataset.scrollto)
        })
      })
    }
  }
}()
